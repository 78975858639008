import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { Link, injectIntl } from "gatsby-plugin-intl"
import { withUserContext } from "../../context/UserContext"
import LoaderSpin from "../../components/LoaderSpin"
import { useForm } from "react-hook-form"
import { isBrowser } from "../../context/ApolloContext"

const ResetPassword = ({ data, userContext, intl }) => {
  const [Password, setPassword] = useState("")
  const [passwordSent, setpasswordSent] = useState(false)
  const [tokenID, settokenID] = useState("")
  const [tokenString, settokenString] = useState("")
  const { handleResetPassword, loading } = userContext
  const resetPassword = () => {
    if (Password && tokenID && tokenString) {
      handleResetPassword(
        "https://percko-eur-staging.myshopify.com/fr/account/reset/3888949035173/e731cc60d350249c5765e364940fb9cc-1600187998",
        Password,
        d => {
          d && setpasswordSent(true)
        }
      )
    } else {
      alert(intl.formatMessage({ id: "empty__email" }))
    }
  }

  const handleParams = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const idParam = urlParams.get("id")
    const tokenParam = urlParams.get("token")
    settokenID(idParam)
    settokenString(tokenParam)
  }

  useEffect(() => {
    handleParams()
  }, [])
  return (
    <FullWrapper>
      <LoaderSpin loading={loading} />
      {data.allDirectusForgotPasswordTranslation && (
        <Wrapper>
          {tokenID && tokenString ? (
            !passwordSent ? (
              <>
                <DisplayText>Put you new password here</DisplayText>
                <EmailBox>
                  <label>New Password</label>
                  <input
                    onChange={e => setPassword(e.target.value)}
                    type="password"
                    value={Password}
                  />
                  <input
                    type="submit"
                    value="Submit"
                    // onClick={tokenID && tokenString && resetPassword}
                  />
                </EmailBox>
              </>
            ) : (
              <EmailBox>
                <strong>Your password is reset</strong>
              </EmailBox>
            )
          ) : (
            <EmailBox>
              <strong>Invalid Token</strong>
            </EmailBox>
          )}
          <GoBackLink>
            <Link to="/">
              {data.allDirectusForgotPasswordTranslation.nodes[0].go_back_text}
            </Link>
          </GoBackLink>
        </Wrapper>
      )}
    </FullWrapper>
  )
}

const FullWrapper = styled.div`
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  width: 320px;
  margin: 50px auto;
`

const DisplayText = styled.div`
  background-color: white;
  border-left: 4px solid black;
  padding: 10px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
`

const EmailBox = styled.div`
  margin-top: 20px;
  border: 1px solid #ccd0d4;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
  padding: 25px;
  background-color: white;
  font-family: "GothamLight";

  > label {
    font-size: 14px;
    font-weight: bold;
    color: #444;
  }

  > input[type="password"] {
    font-size: 14px;
    line-height: 1.33333333;
    width: 100%;
    border-width: 0.0625rem;
    padding: 0.1875rem 0.3125rem;
    margin: 10px 6px 16px 0;
    min-height: 40px;
    max-height: none;
    box-shadow: 0 0 0 transparent;
    border-radius: 4px;
    border: 1px solid #7e8993;
    color: #32373c;
  }

  > input[type="submit"] {
    min-height: 32px;
    line-height: 2.30769231;
    padding: 0 12px;
    vertical-align: baseline;
    /* float: right; */
    background: black;
    border-color: black;
    color: #fff;
    text-decoration: none;
    text-shadow: none;
    font-size: 13px;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    -webkit-appearance: none;
    border-radius: 3px;
    white-space: nowrap;
    box-sizing: border-box;
    margin: 0;
    font-family: "GothamLight";
    margin-left: auto;
  }
`

const GoBackLink = styled.p`
  font-size: 13px;
  padding: 0 0;
  margin: 30px 0;
  font-family: "GothamLight";
  > a {
    text-decoration: none;
    color: #555d66;
    outline: 0;
    transition-property: border, background, color;
    transition-duration: 0.05s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
    :hover {
      font-weight: bold;
    }
  }
`

export const query = graphql`
  query($lang: String!) {
    allDirectusForgotPasswordTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        go_back_text
        email_text
        display_text
        confirm_button
      }
    }
  }
`

export default withUserContext(injectIntl(ResetPassword))
